<template>
  <div class="card">
    <img :src="iconImg" class="iconImg" :class="{security : isSecurity}"/>
    <h3 :class="{security : isSecurity}">{{ $t(title) }}</h3>
    <p>{{ $t(description) }}</p>
  </div>
</template>

<script>
import ConsumerIcon from '@/components/icons/ConsumerFirst.vue'
import RobustIcon from '@/components/icons/RobustTrading.vue'
import SecurityIcon from '@/components/icons/Security.vue'

export default {
  name: "card",
  components: {
    ConsumerIcon, 
    RobustIcon,
    SecurityIcon
  },
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
    icon: { type: String, required: true }
  },
  computed: {
    isSecurity: function() {
      return this.icon === 'SecurityIcon' ? true : false;
    },
    iconImg: function() {
      if  (this.icon === 'ConsumerIcon') {
        return require ('../../assets/img/consumer-first.svg'); 
      } else if  (this.icon === 'RobustIcon') {
        return require ('../../assets/img/robust-trading.svg');
      } else {
        return require ('../../assets/img/security-first.svg');
      }

      return '';
    },
  }
};
</script>

<style lang="scss" scoped>

$card-height: 500px;
$card-width: 396px;
$border-radius: 42px;

.card {
  height: $card-height;
  width: $card-width;
  background: url('../../assets/img/product_box.png') no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: $border-radius;
  border: 0 !important;
  padding: 15px 45px 45px;
  margin: 0 30px;
  position: relative;
  text-align: left;
  overflow: hidden;

  @media (max-width: 1199.98px) and (orientation: landscape) {
    background: url('../../assets/img/product-box-tablet.png') no-repeat;
    background-size: contain;
    text-align: center;
    height: 30.18587360594796vw;
    width: 40vw;
    margin: 0 0 25px;
    padding: 0px 30px 30px;
    border-radius: 30px !important;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    background: url('../../assets/img/product-box-tablet.png') no-repeat;
    background-size: contain;
    text-align: center;
    height: 30.18587360594796vw;
    width: 40vw;
    margin: 0 0 25px;
    padding: 30px 15px;
    border-radius: 30px !important;
  }

  @media (max-width: 767.98px) {
    background: url('../../assets/img/product-box-mobile.png') no-repeat;
    background-size: contain;
    text-align: center;
    height: calc(100vw - 30px);
    width: 100%;
    margin: 0 0 25px;
    padding: 30px 15px;
    border-radius: 30px !important;
  }

  @media (max-width: 767.98px) and (orientation: landscape) { 
    background: url('../../assets/img/product-box-tablet.png') no-repeat;
    background-size: cover;
    text-align: center;
    height: 40vw;
    width: calc(50% - 15px);
    margin: 0 7.5px 25px;
    padding: 20px 15px;
    border-radius: 30px !important;
  }

  .iconImg {
    padding-top: 30px;
    padding-bottom: 10px;
    position: relative;

    @media (min-width: 1200px) {
      height: 130px;
      width: 90px;
    }

    @media (max-width: 1199.98px) {
      height: 100px;
      padding-bottom: 0;
    }

    @media (max-width: 767.98px) {
      width: 50px;
      height: unset;
      padding-top: 0;
      padding-bottom: 0;
      margin: 0 auto 5px;
    }

    @media (max-width: 767.98px) and (orientation: landscape) { 
      height: 35px;
      width: 35px;
      margin: 0 auto;
    }

    &.security {

      @media (max-width: 767.98px) {
        width: 40px;
      }
    }
  }

  h3 {
    font-size: 1.8em;
    color: white;
    top: 20%;
    text-transform: uppercase;
    padding: 20px 0;

    @media (max-width: 1199.98px) {
      padding: 20px 0 10px;
    }

    @media (max-width: 991.98px) {
      font-size: 20px;
      padding: 20px 0;
      margin-top: 20px;
      margin-bottom: 5px;
      margin-top: 10px;
    }

    @media (max-width: 767.98px) and (orientation: landscape) { 
      margin-top: 10px;
    }

    @media (max-width: 575.98px) and (orientation: landscape) { 
      font-size: 16px;
    }

    // &.security {
    //   @media (max-width: 991.98px) {
    //     margin-top: 40px;
    //   }

    //   @media (max-width: 767.98px) {
    //     margin-top: 65px;
    //   }

    //   @media (max-width: 767.98px) and (orientation: landscape) { 
    //     margin-top: 50px;
    //   }

    //   @media (max-width: 575.98px) and (orientation: landscape) { 
    //     margin-top: 50px;
    //   }
    // }
  }

  p {
    color: #f1f1f2;
    top: 30%;

    @media (max-width: 991.98px) {
      font-weight: 300;
    }

    @media (max-width: 767.98px) {
      font-weight: 100;
    }

    @media (max-width: 575.98px) and (orientation: landscape) { 
      font-size: 14px;
    }
  }
}

.cn .card {
  h3 {
    font-size: 34px;
    margin-bottom: 15px;
  }

  p {
    font-size: 16px;
    font-weight: 100;

    @media (max-width: 575.98px) and (orientation: landscape) { 
      font-size: 14px;
    }
  }
}
</style>