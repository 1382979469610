<template>
  <i>
    <img
      src="@/assets/img/security/1.svg"
      alt="lock icon"
      :class="{show: lock}"
      class="locked-icon"
    >
    <img
      src="@/assets/img/security/2.svg"
      alt="lock icon"
      :class="{hide: lock}"
      class="unlocked-icon"
    >
  </i>
</template>

<script>
import { EventBus } from "@/eventBus";

export default {
  name: "security-icon",
  data: function() {
    return {
      lock: false
    };
  },
  mounted() {
    var vw = window.innerWidth;

    if (vw < 768) { // if viewport is mobile
      this.show = true; 
    } else { // if viewport is tablet and larger
      EventBus.$on("in-view", () => {
        this.lock = true;
      });
      EventBus.$on("not-in-view", () => {
        this.lock = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>

i {
  height: 30%;
  width: 30%;
  position: relative;

  @media (max-width: 767.98px) {
    height: 50px;
    width: 50px;
    margin: 0 auto;
    position: absolute;
    left: calc( (100% - 50px) / 2 );
  }

  @media (max-width: 767.98px) and (orientation: landscape) { 
    height: 35px;
    width: 35px;
    margin: 0 auto;
  }

  .unlocked-icon,
  .locked-icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.locked-icon {
  opacity: 0;

  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;

  &.show {
    transition-delay: 3s;
    opacity: 1;
  }
}

.unlocked-icon {
  opacity: 1;

  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;

  &.hide {
    transition-delay: 3s;
    opacity: 0;
  }
}
</style>

