@import '../../../assets/css/project.scss';

<template>
    <button class="downloadBtn" @click="EventBusShowQRcode">
      <span class="icon" :class="platform"></span>
      {{ $t(`download.${platform}`) }}
    </button>
</template>

<script>
import { EventBus } from "@/eventBus.js";

export default {
  props: {
    platform: { type: String, required: true }
  },
  methods: {
    EventBusShowQRcode() {
      EventBus.$emit("show-qr-code");
    }
  }
};
</script>

<style lang="scss" scoped>

.downloadBtn {
  cursor: pointer;
  display: inline-block;
  background-color: rgba(#28316f, 0.4);
  border: none;
  border-radius: 30px;
  outline: none;

  color: white;
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  text-shadow: 0 0 10px #333;
  line-height: 2.1;
  vertical-align: middle;

  padding: 5px 15px 5px 5px;

  @media (max-width: 991.98px) {
    font-size: 14px;
    padding: 10px 15px 10px 10px;
  }

  -webkit-box-shadow: 5px 5px 10px 0px rgba(#333, 0.45);
  box-shadow: 5px 5px 10px 0px rgba(#333, 0.45);
  -webkit-transition: background-color 0.4s;
  transition: background-color 0.4s;

  &:hover {
    background-color: #28316f;
  }
  &:active {
    background-color: #28316f;
  }

  .icon {
    float: left;
    width: 40px;
    height: 40px;
    margin-right: 5px;

    @media (max-width: 991.98px) {
      width: 25px;
      height: 25px;
    }
  }

  .ios {
    background: url("../../assets/img/apple-white.svg") no-repeat;
  }

  .andriod {
    background: url("../../assets/img/andriod-white.svg") no-repeat;
  }

  .span {
    float: left;
    width: 20px;
    height: 20px;
  }

  &:focus {
    outline: none;
  }
}

.cn .downloadBtn {
  padding: 5px 30px 5px 15px ;
}
</style>
