<template>
  <div class="text-block-2">
    <div class="number">{{ number }}</div>
    <div class="right text-left">
      <h2 class="title">{{ $t(title) }}</h2>
      <h3 class="subtitle">{{ $t(subtitle) }}</h3>
      <p class="description">{{ $t(description) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TextBlock",
  props: {
    number: String,
    title: String,
    subtitle: String,
    description: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.text-block-2 {
  display: flex;
  .number{
    width: 20%;
    padding: 10px;
    color: #56838b;
    font-weight: 700;
  }
  .right {
    width: 80%;
    padding: 8px 5%; 
    .title { 
      color: #56838b;
      margin-bottom: 0;
    }

    .subtitle { 
      color: #404041;
      font-weight: 400;
    }

    .description {
      color: #58595b;
      padding-top: 18px;
    }
  }
}

.cn .text-block-2 {
  .title {
    font-weight: 700;
  }

  .subtitle {
    font-size: 22px;
  }

  .description {
    font-weight: 100;
  }
}
</style>
