<template>
  <i>
    <transition enter-active-class="animated fadeIn delay-3s slow">
      <img src="@/assets/img/trading/1.svg" alt="frame" class="frame" v-if="show">
    </transition>
    <transition enter-active-class="animated fadeIn delay-2s slow">
      <img src="@/assets/img/trading/2.svg" alt="signal-1" class="signal-1" v-if="show">
    </transition>
    <transition enter-active-class="animated fadeIn delay-1s slow">
      <img src="@/assets/img/trading/3.svg" alt="signal-2" class="signal-2" v-if="show">
    </transition>
    <transition enter-active-class="animated fadeIn slow">
      <img src="@/assets/img/trading/4.svg" alt="signal-3" class="signal-3" v-if="show">
    </transition>
  </i>
</template>

<script>
import { EventBus } from "@/eventBus";
export default {
  name: "trading-icon",
  data: function() {
    return {
      show: false
    };
  },
  mounted() {
    var vw = window.innerWidth;

    if (vw < 768) { // if viewport is mobile
      this.show = true; 
    } else { // if viewport is tablet and larger
      EventBus.$on("in-view", () => {
        this.show = true;
      });
      EventBus.$on("not-in-view", () => {
        this.show = false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>

$signal1Size: 13%;
$signal2Size: 9%;
$signal3Size: 5%;

i {
  width: 35%;
  height: 35%;
  position: relative;

  @media (max-width: 767.98px) {
    height: 50px;
    width: 50px;
    margin: 0 auto;
  }

  @media (max-width: 767.98px) and (orientation: landscape) { 
    height: 34px;
    width: 34px;
    margin: 0 auto;
  }

  .signal-1,
  .signal-2,
  .signal-3 {
    position: absolute;
  }
  .signal-1 {
    height: $signal1Size;
    width: $signal1Size;
    bottom: 71.8%;
    left: 13%;

    @media (max-width: 767.98px) {
      height: 27px;
      width: 27px;
      bottom: 8px;
      left: calc( (100% - 50px) / 2);
    }

    @media (max-width: 767.98px) and (orientation: landscape) { 
      height: 18px;
      width: 18px;
      bottom: 6px;
      left: calc( (100% - 34px) / 2);
    }
  }
  .signal-2 {
    height: $signal2Size;
    width: $signal2Size;
    bottom: 72.4%;
    left: 13%;

    @media (max-width: 767.98px) {
      height: 19px;
      width: 19px;
      bottom: 8px;
      left: calc( (100% - 50px) / 2);
    }

    @media (max-width: 767.98px) and (orientation: landscape) { 
      height: 13px;
      width: 13px;
      bottom: 6px;
      left: calc( (100% - 34px) / 2);
    }
  }
  .signal-3 {
    height: $signal3Size;
    width: $signal3Size;
    bottom: 72.7%;
    left: 13%;

    @media (max-width: 767.98px) {
      height: 11px;
      width: 11px;
      bottom: 8px;
      left: calc( (100% - 50px) / 2);
    }

    @media (max-width: 767.98px) and (orientation: landscape) { 
      height: 8px;
      width: 8px;
      bottom: 6px;
      left: calc( (100% - 34px) / 2);
    }
  }
}
</style>

