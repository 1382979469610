<template>
  <i>
    <img src="@/assets/img/consumer-first/1.svg" alt="consumer head icon" class="head">
    <transition enter-active-class="animated fadeIn slow">
      <img
        src="@/assets/img/consumer-first/2.svg"
        alt="consumer head signal1"
        class="signal-1"
        v-if="show"
      >
    </transition>
    <transition enter-active-class="animated fadeIn delay-2s slow">
      <img
        src="@/assets/img/consumer-first/3.svg"
        alt="consumer head signal2"
        class="signal-2"
        v-if="show"
      >
    </transition>
  </i>
</template>

<script>
import { EventBus } from '@/eventBus'
export default {
  name: "consumer-icon",
  data: function() {
    return {
      show: true
    };
  }, 
};
</script>

<style lang="scss" scoped>

i {
  width: 30%;
  height: 30%;
  position: relative;

  @media (max-width: 767.98px) {
    height: 50px;
    width: 50px;
    margin: 0 auto;
  }

  @media (max-width: 767.98px) and (orientation: landscape) { 
    height: 35px;
    width: 35px;
    margin: 0 auto;
  }

  .signal-1 {
    position: absolute;
    height: 25%;
    width: 25%;
    left: 80%;
    top: 5%;
  }
  .signal-2 {
    position: absolute;
    height: 50%;
    width: 50%;
    left: 90%;
    top: -7%;
  }
}
</style>

